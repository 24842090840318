<template>
     <div class="container-fluid px-xl-4 py-4">
       
    <div class="part-list custom-card vue-form-wizard ">
        <div class="page-header f-right">
            <b-button class="btn custom-btn pattern-btn" @click="dialogAlert = true"><span>Print</span></b-button>
        </div>
        <div class="wizard-tab-content"id="print">
            <div v-if="allParts">
                <div v-for="(carProfile, outerIndex) in allParts" :key="outerIndex">
                    <div style="margin: 20px 0;" v-for="(i, index) in carProfile.car_parts" :key="index"
                        :class="((selectCol == 2 && getGlobalIndex(outerIndex, index) % selectCol == 0 && getGlobalIndex(outerIndex, index) != 0) || selectCol == 1) ? 'page-break' : 'not-page-break'">
                        <div :class="'box-border mb-3 box-' + getGlobalIndex(outerIndex, index)">
                            <!-- :style="selectCol == 2 ? 'margin-right: 15px;display: flex;flex-direction: column;min-width: 377px;' : ''" -->
                            <div class="center-of-box">
                                <div class="inner-circle">
                                    <div class="tiny_circle"></div>
                                </div>
                            </div>
                            <div class="center-of-box">
                                <barcode v-bind:value="i.id" width="3" height="60" displayValue="false">
                                    Show this if the rendering fails.
                                </barcode>
                            </div>
                            <div v-if="carProfile.user.user_detail.company_name">
                                <div class="uppercase_bold">{{ carProfile.user.user_detail.company_name }}</div>
                            </div><br>
                            <div class="display-flex" v-if="i.vin && i.profile_type == 'auction'">
                                <div class="left-side uppercase_bold">VIN</div>
                                <div class="right-side uppercase_text">{{ i.vin }}</div>
                            </div>
                            <div class="display-flex" v-if="i.part_name">
                                <div class="left-side uppercase_bold">PART NAME</div>
                                <div class="right-side">{{ i.part_name }}</div>
                            </div>
                            <div class="display-flex" v-if="i.year">
                                <div class="left-side uppercase_bold">YEAR</div>
                                <div class="right-side">{{ i.year }}</div>
                            </div>
                            <div class="display-flex" v-if="i.make">
                                <div class="left-side uppercase_bold">MAKE</div>
                                <div class="right-side">{{ i.make }}</div>
                            </div>
                            <div class="display-flex" v-if="i.model">
                                <div class="left-side uppercase_bold">MODEL</div>
                                <div class="right-side">{{ i.model }}</div>
                            </div>
                            <div class="display-flex" v-if="i.recommended_sale_price">
                                <div class="left-side uppercase_bold">PRICE</div>
                                <div class="right-side">{{ i.recommended_sale_price | Currencyfilter(country) }}</div>
                            </div>
                            <div class="display-flex" v-if="i.interchange_code">
                                <div class="left-side uppercase_bold">INTERX</div>
                                <div class="right-side">{{ i.interchange_code }}</div>
                            </div>
                            <div class="display-flex" v-if="i.part_grade">
                                <div class="left-side uppercase_bold">GRADE</div>
                                <div class="right-side">{{ i.part_grade }}</div>
                            </div>
                            <div class="display-flex" v-if="i.warranty_days">
                                <div class="left-side uppercase_bold">WARRANTY</div>
                                <div class="right-side">{{ i.warranty_days }}</div>
                            </div>
                            <div class="display-flex" v-if="i.miles">
                                <div class="left-side uppercase_bold">MILES</div>
                                <div class="right-side">{{ i.miles }}</div>
                            </div>
                            <div class="display-flex" v-if="i.description">
                                <div class="left-side uppercase_bold"> {{ i.profile_type != 'auction' ? 'LOCATION & ' :
                                    '' }}NOTES</div>
                                <div class="right-side">{{ i.description }}</div>
                            </div>
                            <div class="display-flex" v-if="i.created_at">
                                <div class="left-side uppercase_bold">DATE</div>
                                <div class="right-side">{{ i.created_at | MMDDYYdatefilter }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="my-modal" centered no-close-on-backdrop hide-footer title="Print Barcode">
            <div class="d-block text-center">
                <b-button class="mt-2 btn custom-btn pattern-btn" variant="outline-danger" @click="printBarcode(1)"><span>1 barcode in 1 page</span></b-button>
                <b-button class="mt-2 ml-2 btn custom-btn pattern-btn" variant="outline-danger" @click="printBarcode(2)"><span>2 barcode in 1
                    page</span></b-button>
                <b-button class="ml-2 mt-2 btn custom-btn custom-danger" @click="dialogAlert = false">{{
                    $t("confirmationDialog.cancel")
                }}</b-button>
            </div>
        </b-modal>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { showFailure } from "@/eventbus/action";
import VueBarcode from 'vue-barcode';
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
    components: {
        'barcode': VueBarcode
    },
    data() {
        return {
            dialogAlert: false,
            selectCol: 1,
            allParts: null,
            country: 'us'
        }
    },
    props: {
        ids: {
            type: String | Array,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            user: "user/getUser",
            carDetailData: "carlist/getAllPrintParts",
            allPartsByGroupId: "carlist/getAllPartsByGroupId",
            allPartsByIds: "carlist/getAllPartsByIds",
            allPartsByBetweenDates: "carlist/getAllPartsByBetweenDates",
        }),
    },
    watch: {
        dialogAlert(val) {
            if (val) {
                this.$refs["my-modal"].show();
            } else {
                this.$refs["my-modal"].hide();
            }
        },
        printDialog(val) {
            if (val) {
                this.showModal();
            } else {
                this.hideModal();
            }
        },
        printPartId(val) {
            if (val) {
                this.getPartById();
            }
        }
    },
    async mounted() {
        if (this.type == 'car_profile_id') {
            await this.getCarData();
        } else if (this.type == 'group_id') {
            await this.getPartsDataByGroupId();
        } else if (this.type == 'part_id') {
            await this.getPartsDataByIds();
        } else if (this.type == 'filtered_date') {
            var startDate = moment(this.ids.startDate).format('YYYY-MM-DD');
            var endDate = moment(this.ids.endDate).format('YYYY-MM-DD');
            await this.getPartsDataByBetweenDates({ startDate, endDate });
        }
        this.country = await this.user?.user_detail?.country || 'us';
    },
    methods: {
        ...mapActions({
            getAllPrintParts: "carlist/getAllPrintParts",
            getAllPartsByGroupId: "carlist/getAllPartsByGroupId",
            getAllPartsByIds: "carlist/getAllPartsByIds",
            getAllPartsByBetweenDates: "carlist/getAllPartsByBetweenDates",
        }),

        getGlobalIndex(outerIndex, index) {
            let globalIndex = 0;
                for (let i = 0; i < outerIndex; i++) {
                    globalIndex += this.allParts[i].car_parts.length;
                }
                return globalIndex + index;
    },

        async getCarData() {
            try {
                this.$store.commit("setLoader", true);
                await this.getAllPrintParts(this.ids);
                this.allParts = [await this.carDetailData];
            } catch ({ message }) {
                const errorMessage =
                    typeof message === "object" ? Object.values(message)[0][0] : message;
                showFailure(errorMessage);
            } finally {
                this.$store.commit("setLoader", false);
            }
        },
        async getPartsDataByGroupId() {
            try {
                this.$store.commit("setLoader", true);
                await this.getAllPartsByGroupId(this.ids);
                this.allParts = await this.allPartsByGroupId;
            } catch ({ message }) {
                const errorMessage =
                    typeof message === "object" ? Object.values(message)[0][0] : message;
                showFailure(errorMessage);
            } finally {
                this.$store.commit("setLoader", false);
            }
        },
        async getPartsDataByIds() {
            try {
                this.$store.commit("setLoader", true);
                await this.getAllPartsByIds({ ids: this.ids });
                this.allParts = await this.allPartsByIds;
            } catch ({ message }) {
                const errorMessage =
                    typeof message === "object" ? Object.values(message)[0][0] : message;
                showFailure(errorMessage);
            } finally {
                this.$store.commit("setLoader", false);
            }
        },
        async getPartsDataByBetweenDates(payload) {
            console.log(payload);
            try {
                this.$store.commit("setLoader", true);
                await this.getAllPartsByBetweenDates(payload);
                this.allParts = await this.allPartsByBetweenDates;
            } catch ({ message }) {
                const errorMessage =
                    typeof message === "object" ? Object.values(message)[0][0] : message;
                showFailure(errorMessage);
            } finally {
                this.$store.commit("setLoader", false);
            }
        },
        async printBarcode(col) {
            this.dialogAlert = await false;
            this.selectCol = await col;
            await setTimeout(async () => {
                await this.handleOk();
            }, 200);
        },
        handleOk() {
            // Get HTML to print from element
            const prtHtml = document.getElementById('print').innerHTML;
            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }
            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                    <style>
                        #print {
                            font-size: 13px;
                            margin: 0 auto;
                            width: 100%;
                        }
                        @page {
                            size: A4;
                            margin: 20px;
                        }
                        .center-of-box {
                            margin: 0 auto;
                            display: table;
                        }

                        .display-flex {
                            display: flex;
                        }

                        .left-side {
                            width: 40%;
                        }

                        .right-side {
                            width: 60%;
                        }

                        .padding10 {
                            padding: 10px;
                        }

                        .box-border {
                            margin: auto;
                            border: 3px #000 solid;
                            max-width: 377px;
                            width: 100%;
                            min-height: 420px;
                            padding: 10px 30px;
                        }

                        .uppercase_bold {
                            text-transform: uppercase;
                            font-weight: bold;
                        }

                        .uppercase_text {
                            text-transform: uppercase;
                        }

                        .inner-circle {
                            border-radius: 50%;
                            width: 30px;
                            border: 4px #000 solid;
                        }

                        .tiny_circle {
                            margin: auto;
                            border-radius: 50%;
                            height: 23px;
                        }

                        @media print {
                            html, body {
                                width: 210mm;
                                height: 297mm;
                            }
                            .page-break {
                                page-break-before: always;
                            }
                            .not-page-break {
                                page-break-after: avoid;
                            }
                        }

                        .page-break {
                            page-break-before:always;
                        }
                        .not-page-break {
                            page-break-after: avoid;
                        }
                    </style>
                </head>
                <body>
                    ${prtHtml}
                </body>
                </html>`);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            setTimeout(() => {
                    WinPrint.close();
                }, 150);
        },
    }
}
</script>
